import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useDownload } from "../lib/downloadUtils";
import Popup from "../components/popup-download";
import { useSearchParams } from "react-router-dom";
import { copyToClipboard } from "../lib/utils";

// These imports are necessary for Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const images = [
  "/v2/screens/gamescreen00001.png",
  "/v2/screens/gamescreen00002.png",
  "/v2/screens/gamescreen00003.png",
  "/v2/screens/gamescreen00004.png",
  "/v2/screens/gamescreen00005.png",
];

export default function Index() {
  const [searchParams] = useSearchParams();
  const { onDownload } = useDownload();
  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = async () => {
    const result = await onDownload();
    if (result === "popup") {
      setShowPopup(true);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const inviteCode = searchParams.get("invite");
    if (inviteCode) {
      copyToClipboard(inviteCode as string);
    }
  }, [searchParams]);

  return (
    <div className="bg-white h-screen text-white font-sans max-w-[531px] mx-auto relative">
      <div
        className="absolute top-5 right-0 z-10"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://tawk.to/chat/66f2aea44cbc4814f7dde97f/1i8hvkcq2",
            "_blank"
          );
        }}
      >
        <img src="/v2/support.png" alt="support" className="w-20" />
      </div>
      <img src="/v2/header.jpg" alt="logo" />
      <div className="relative">
        <img src="/v2/gamebonus.jpg" alt="logo" />
        <img
          className="w-2/3 absolute bottom-0 left-1/2 -translate-x-1/2 "
          src="/v2/download.png"
          alt="logo"
          onClick={handleDownloadClick}
        />
      </div>
      <div
        style={{ backgroundImage: "url(/v2/bg.jpg)" }}
        className="bg-cover bg-no-repeat bg-center -mt-[0.3px]"
      >
        <div className="relative overflow-hidden p-2">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            className="mySwiper relative"
          >
            {images.map((image) => (
              <SwiperSlide key={image}>
                <div className="relative  ">
                  <img
                    src={image}
                    alt={image}
                    className="w-full h-full object-cover "
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        onClick={handleDownloadClick}
        src="/v2/bottombanner.jpg"
        alt="logo"
        className="sticky bottom-0 w-full z-10"
      />
      {showPopup && <Popup onClose={handleClosePopup} />}
    </div>
  );
}
