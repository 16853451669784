import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./i18n";

// import HomePage from "./pages/home";
import IndexPage from "./pages/index";
// import DownloadPage from "./pages/download";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        {/* <Route path="/download" element={<DownloadPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
